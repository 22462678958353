export const ExperienceWorkModel = [
    
    {
        name: "ClickNext Ltd.",
        role: "iOS Developer",
        startDate: new Date("2022-06-01"),
        workType: "Internship",
        endDate: new Date("2022-11-28")
    }, {
        name: "ClickNext Ltd.",
        role: "iOS Developer",
        startDate: new Date("2023-07-03"),
        workType: "Full-time"
    }
    
]